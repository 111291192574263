import React, { FC } from "react";

export const EmailIcon: FC = () => (
  <svg
    width="20"
    height="16"
    viewBox="0 0 20 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 2.42124C0 2.42124 8.37418 7.2 9.99958 7.2C11.7032 7.2 19.9867 2.51253 19.9867 2.51253L20 1.67502C20 0.75041 19.2543 0 18.333 0H1.66618C0.745703 0 0 0.75041 0 1.67502V2.42124ZM19.9867 4.8002C19.9867 4.8002 11.7814 9.07771 9.99958 9.07771C8.37418 9.07771 0.0124839 4.8002 0.0133161 4.8002L0 14.4002C0 15.2834 0.746536 16.0002 1.66618 16.0002H18.333C19.2543 16.0002 20 15.2834 20 14.4002L19.9867 4.8002Z"
      fill="white"
    />
  </svg>
);
