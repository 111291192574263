import React, { FC } from "react";

export const LinkedInIcon: FC = () => (
  <svg
    width="22"
    height="21"
    viewBox="0 0 22 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.20062 21H0.846875V6.97969H5.20062V21ZM3.02141 5.06719C1.62922 5.06719 0.5 3.91406 0.5 2.52188C0.5 1.85316 0.765647 1.21183 1.2385 0.738972C1.71136 0.266117 2.35269 0.000469208 3.02141 0.000469208C3.69012 0.000469208 4.33145 0.266117 4.80431 0.738972C5.27716 1.21183 5.54281 1.85316 5.54281 2.52188C5.54281 3.91406 4.41313 5.06719 3.02141 5.06719ZM21.4953 21H17.1509V14.175C17.1509 12.5484 17.1181 10.4625 14.8873 10.4625C12.6237 10.4625 12.2769 12.2297 12.2769 14.0578V21H7.92781V6.97969H12.1034V8.89219H12.1644C12.7456 7.79063 14.1655 6.62813 16.2838 6.62813C20.69 6.62813 21.5 9.52969 21.5 13.2984V21H21.4953Z"
      fill="white"
    />
  </svg>
);
